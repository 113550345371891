import React from 'react';
import Section from '../section/Section.js';
import Card from '../card/Card';
import './Productos.css';

/*images*/
import img1 from './images/1_light.png';
import img2 from './images/2_light.png';
import img3 from './images/3_light.png';
import img4 from './images/4_light.png';
import img5 from './images/5_light.png';
import img6 from './images/jamonqueso.png';
import img7 from './images/chipa_dulce.png';
import img8 from './images/couxipa.png';
import img9 from './images/masa.png';
import img10 from './images/bolitas.png';
import img11 from './images/gourmet.png';
import img12 from './images/chipanceta.png';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import SliderButtons from '../slider_buttons/SliderButtons';
import Fade from 'react-reveal/Fade';
class Productos extends React.Component {
    constructor(){
        super();
        this.state = {
             slides_to_pass: 1,
        }
    }

     update_slider = () =>{
        // get all the elements inside the slider 
        const container_ = document.querySelector(".section.productos .elements-container");
        const container_width = container_.offsetWidth;
        const element_width = 280+50;

        //number of elements to hide
        const num_elements_to_show = Math.floor(container_width/element_width);
        console.log(num_elements_to_show);
        this.setState({slides_to_pass: num_elements_to_show})
    }

    componentDidMount() {
        this.update_slider()
        const updateSlider = this.update_slider;
        window.addEventListener('resize', function () {updateSlider()})
    }

    render(){
        /* set the elements to show in the slider*/
        const productos = [
                        {'id': 1,'title': 'Chipa Tradicional','description': 'El sabor de siempre','img':img1},
                        {'id': 2,'title': 'Chipa Argolla','description': 'Más sabor','img':img2},
                        {'id': 3,'title': 'Chipa Quesú','description': 'Rellena de queso Paraguay','img':img3},
                        {'id': 4,'title': 'Chipa So\'ó','description': 'Rellena de carne','img':img4},
                        {'id': 5,'title': 'Chipá Mestizo','description': 'Con harina de maíz','img':img5},
                        {'id': 6,'title': 'Chipa Jamon y Queso ','description': 'Arrollado con jamón y queso','img':img6},
                        {'id': 7,'title': 'Chipa Dulce','description': 'Rellena de dulce de guayaba','img':img7},
                        {'id': 8,'title': 'Chipanceta ','description': 'Chipa con crocantes de panceta','img':img12},
                        {'id': 9,'title': 'Coxipa','description': 'Rellena de pollo y catupyry','img':img8},
                        {'id': 10,'title': 'Chipas Ultra Congeladas','description': 'Para cocinar en donde quieras','img':img9},
                        {'id': 11, 'title': 'Chipa Gourmet ','description': 'Rellena de queso roquefort','img':img11},
                        {'id': 12,'title': 'Chipacitos ','description': 'Bolitas de chipa','img':img10},
                        /*{'id': 13,'title': 'Chipa so\'ó','description': 'Rellena de carne','img':img1},
                        {'id': 14,'title': 'Chipa so\'ó','description': 'Rellena de carne','img':img2},*/
                    ];
        const responsive = {
          superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 3000, min: 1281 },
            items: 4,
          },
          desktop: {
            breakpoint: { max: 1280, min: 961 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 960, min: 641 },
            items: 2,
          },
          mobile: {
            breakpoint: { max: 640, min: 0 },
            items: 1,
          },
        };

        return (
            <Section id="productos" className="productos">
              <Fade top>
               <div className="title-container">
                    <h1 className="title one uppercase">M&aacute;s de 12 </h1>
                    <h1 className="title one">variedades</h1>
                    <h1 className="title two">de chipas</h1>
               </div>

                <div className="elements-container">

                   <Carousel
                      additionalTransfrom={0}
                      arrows={false}
                      autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      autoPlaySpeed={2000}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      customButtonGroup={<SliderButtons />}
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={true}
                      renderDotsOutside={false}
                      responsive={responsive}
                      showDots={true}
                      sliderClass=""
                      slidesToSlide={this.state.num_elements_to_show}
                      swipeable
                    >
                       {productos.map(function (product) {
                          return <Card title={product.title} description={product.description} img={product.img} key={product.id}/>
                        })}
                    </Carousel>
                </div>
                </Fade>
            </Section>
        );
    }
    
}

export default Productos;
