import React from 'react';
import './ContactElement.css';

const ContactElement = (props) => {
  /*<FontAwesomeIcon icon={faChevronLeft}/>*/
    return (
    	<div className="contact-element">
    		<h1 className="title">{props.title}</h1>
    		<h1 className="value">{props.value}</h1>
    	</div>
    );
}

export default ContactElement;