import React, { Component } from 'react';
import './App.css';

import NavBar from './components/navbar/NavBar';
import Inicio from './components/inicio/Inicio';
import Historia from './components/historia/Historia';
import Productos from './components/productos/Productos';
import Chipalitos from './components/chipalitos/Chipalitos';
import ChipatiaExpress from './components/chipatiaexpress/ChipatiaExpress';
import Servicios from './components/servicios/Servicios';
import Contactos from './components/contactos/Contactos';


class App extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <Inicio />
        <Historia />
        <Productos />
        <Chipalitos />
        <ChipatiaExpress />
        <Servicios />
        <Contactos />
      </div>
    );
  }
}

export default App;
