import React from 'react';
import Section from '../section/Section.js';
import './Inicio.css';

import ChipaBlurry from './images/chipa_blurry_light.png';
import Fachada from './images/fachada.png';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class Inicio extends React.Component {
    render(){
        const responsive = {
          superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 3000, min: 1281 },
            items: 1,
          },
          desktop: {
            breakpoint: { max: 1280, min: 961 },
            items: 1,
          },
          tablet: {
            breakpoint: { max: 960, min: 641 },
            items: 1,
          },
          mobile: {
            breakpoint: { max: 640, min: 0 },
            items: 1,
          },
        };
        return (
        	<Section id="home" className="inicio">
                <div style={{width:'100%'}}>
                    <Carousel
                      additionalTransfrom={0}
                      arrows={false}
                      autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      autoPlaySpeed={5000}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={responsive}
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                    >
                        <div className="items-container">
                            <div className="text-container">
                                <h1>Calidad</h1>
                                <h1>Excelencia</h1>
                                <h1>Tradición</h1>
                                <h1 className="last">
                                    Bogadense
                                </h1>
                            </div>
                            <div className="img-container">
                                <img src={ChipaBlurry} alt="chipa argolla" />
                            </div>
                        </div>
                        <div className="items-container-two">
                            <div className="text-container">
                                <h1>M&aacute;s de </h1>
                                <h1>20 años</h1>
                                <h1>de historia</h1>
                            </div>
                            <div className="img-container" >
                                <img src={Fachada} alt="fachada chipa tia" />
                            </div>
                        </div>
                    </Carousel>
                </div>
        	</Section>
        );       
    }
}

export default Inicio;
