import React from 'react';
import Section from '../section/Section.js';
import Card from '../card/Card';
import './Servicios.css';

/*images*/
import Buffet from './images/buffet.png';
import Minutas from './images/minutas.png';
import Catering from './images/catering.png';
import Eventos from './images/eventos.png';
import Canastas from './images/canastas.png';
import Presentes from './images/presentes.png';

import SliderButtons from '../slider_buttons/SliderButtons';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Fade from 'react-reveal/Fade';

class Servicios extends React.Component {
    render(){
      const servicios = [
                          {'id': 1,'title': 'Buffet','description': 'El buffet más completo de Coronel Bogado. Abierto de lunes a lunes de 11:00hs a 14:30hs','img':Buffet},
                          {'id': 2,'title': 'Minutas','description': 'Encontrarás una amplia variedad de sabores listos para llevar.','img':Minutas},
                          {'id': 3,'title': 'Catering','description': 'Realizamos servicios de catering para todos tus eventos','img':Catering},
                          {'id': 4,'title': 'Eventos','description': 'Alquiler de espacios para tus celebraciones (baby shower, cumpleaños, acontecimientos especiales)','img':Eventos},
                          {'id': 5,'title': 'Canastas','description': 'Elaboramos canastas personalizadas para regalar tu detalle perfecto','img':Canastas},
                          {'id': 6,'title': 'Presentes','description': 'Consultá por los presentes que tenemos para regalar','img':Presentes},
                      ];
      const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 3000, min: 1281 },
              items: 4,
            },
            desktop: {
              breakpoint: { max: 1280, min: 961 },
              items: 3,
            },
            tablet: {
              breakpoint: { max: 960, min: 641 },
              items: 2,
            },
            mobile: {
              breakpoint: { max: 640, min: 0 },
              items: 1,
            },
          };
      return (
      	<Section id="servicios" className="servicios">
            <Fade bottom>
               <div className="title-container">
                    <h1 className="title one uppercase">Te </h1>
                    <h1 className="title two">ofrecemos</h1>
               </div>
            </Fade>
            <Fade left>
              <div className="elements-container">
                 <Carousel
                    additionalTransfrom={0}
                    arrows = {false}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    customButtonGroup={<SliderButtons />}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={true}
                    renderDotsOutside={false}
                    responsive={responsive}
                    showDots={true}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                  >
                    {servicios.map(function (servicio) {
                      return <Card title={servicio.title} description={servicio.description} img={servicio.img} key={servicio.id}/>
                    })}   
                  </Carousel>
                </div>
              </Fade>
      	</Section>
      );
    }
}

export default Servicios;
