import React from 'react';
import Section from '../section/Section.js';
import ContactElement from '../contact_element/ContactElement';
import './Contactos.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Fade from 'react-reveal/Fade';

const Contactos = () => {
  /*<FontAwesomeIcon icon={faChevronLeft}/>*/
    return (
    	<Section id="contactos" className="contactos">
        <Fade top>
          <div className="title-container">
                <h1 className="title one">Contact&aacute;</h1>
                <h1 className="title two">con nosotros</h1>
           </div>
        </Fade>
           <div className="contact-grid">
              <Fade left>
              <ContactElement title="Tel&eacute;fono:" 
                              value="0741 - 252 523"/>
              </Fade>
              <Fade right>
              <ContactElement title="Celular:" 
                              value="+595 984 - 578 338"/>
              </Fade>
              <Fade right>
              <ContactElement title="Direcci&oacute;n:" 
                              value="Ruta N° 1 y Avda. Pedro Juan Caballero"/>
              </Fade>
              <Fade left>
              <div className="social-media">
               
                  <a href="https://es-la.facebook.com/ChipaTiaPy/" target='blank'>
                    <span><FontAwesomeIcon icon={faFacebookF}/></span>
                  </a>
                  <a href="https://www.instagram.com/Chipa_Tia/" target='blank'>
                    <span><FontAwesomeIcon icon={faInstagram}/></span>
                  </a>
              </div>
              </Fade>
              <br/>
              <Fade right>
              <ContactElement title="" 
                              value="gerencia@chipatia.com.py"/>
              </Fade>
           </div>
    	</Section>
    );
}

export default Contactos;