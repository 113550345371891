import React from 'react';
import './Card.css';
const Card = (props) => {
    return (
    	<div className="card">
    		<div className="img-container" style={{backgroundImage: `url(${props.img})`}}></div>
    		<div className="text-container">
    			<h2 className="title">{props.title}</h2>
    			<span className="description">{props.description}</span>
    		</div>
    	</div>
    );
}

export default Card;
