import React from 'react';
import Section from '../section/Section.js';
import './Historia.css';
import Fade from 'react-reveal/Fade';

const Historia = () => {
    return (
        <React.Fragment>
        	<Section id="historia" className="historia">
                <Fade bottom>    
                   <div className="title-container">
                        <h1 className="title one">Nuestra</h1>
                        <h1 className="title two">Historia</h1>
                   </div>
                </Fade>
                <Fade>
                   <div className="paragraph">
                        Somos una empresa familiar inaugurada en 1997 a partir de la receta original de “Tía Gertrudis” a 
                        quién se debe el nombre y los primeros clientes de la auténtica chipería de Coronel Bogado “CHIPA TÍA”.
                        <br/>
                        Al transcurrir el tiempo, acompañado de la dedicación en la labor de crear nuevos sabores, brindar servicios 
                        tanto a lugareños como viajeros y manteniendo la base de la tradición en nuestra gastronomía, hoy somos 
                        punto de referencia en la Capital Nacional del Chipá.
                        <br/>
                        El acompañamiento de familiares y amigos fue fundamental para que, luego de más de dos décadas, 
                        la receta original quede instaurada como marca y legado a nivel nacional, impulsando la revalorización 
                        de nuestra cultura a través de nuestros productos y con cada cliente que vive la experiencia de probar 
                        nuestra tradición y algo más…
                   </div>
                </Fade>

        	
            <div id="mision_vision" className="mision_vision">
                <div className='mision_container'>
                    <div className='mision_text'>
                       <div className="title-container">
                            <h1 className="title one">Misión</h1>
                       </div>
                       <div className="paragraph">
                                Nosotros somos quienes ofrecemos el sabor 
                                tradicional de la chipa de Coronel Bogado 
                                a todo Paraguay superando las expectativas de nuestros 
                                clientes, a través de un equipo comprometido 
                                con la calidad y la innovación, 
                                hacia dentro de la empresa como con 
                                el exterior de ella.
                       </div>
                    </div>

                </div>

                <div className='vision_container'>
                    <div className='vision_text'>
                        <div className="title-container">
                            <h1 className="title one">Visión</h1>
                       </div>
                        <div className="paragraph">
                           Ser la empresa que lleva el sabor tradicional de la chipa de 
                           Coronel Bogado a todo Paraguay, reconocida por el incomparable 
                           sabor de sus productos, la excelencia de sus servicios, mediante 
                           la aplicación de un mejoramiento continuo y la innovación en todos 
                           nuestros procesos con el afán de conquistar la satisfacción de los 
                           clientes.
                       </div>
                    </div>
                </div>
            </div>
            <div className="valores_container">
                <div className="title-container">
                    <h1 className="title one">Valores</h1>
               </div>
               <div className="valores_texto">
                    <div className="valor">
                        <div className='ph_1'>
                            <div className="title-container">
                                <h1 className="title two" style={{fontSize: '3.5rem', marginTop: '0.8rem'}}>Calidad e innovación</h1>
                           </div>
                        </div>
                        <div className="paragraph">
                        buscando la excelencia en el día a día logrando la mejora continua

                        </div>
                    </div>
                    <div className="valor">
                        <div className='ph_1'>
                            <div className="title-container">
                                <h1 className="title two" style={{fontSize: '3.5rem', marginTop: '0.8rem'}}>Amor al trabajo</h1>
                           </div>
                        </div>
                        <div className="paragraph">
                        Demostrar constancia en la labor que realiza, ser esmerado, aplicado, exhibir interés y disposición, 
                        así como satisfacción por la tarea que realiza.
                        </div>
                    </div>
                    <div className="valor">
                         <div className='ph_1'>
                            <div className="title-container">
                                <h1 className="title two" style={{fontSize: '3.5rem', marginTop: '0.8rem'}}>Sentido de pertenencia a la organización</h1>
                           </div>
                        </div>  
                        <div className="paragraph">
                        Demostración de orgullo por los éxitos de la organización a la que pertenece 
                        y mostrar preocupación sincera ante las dificultades de la empresa. 
                        Tener voluntad para participar en los asuntos colectivos, 
                        respondiendo positivamente ante las obligaciones contraídas. 
                        </div>
                    </div>
                    <div className="valor">
                        <div className='ph_1'>
                            <div className="title-container">
                                <h1 className="title two" style={{fontSize: '3.5rem', marginTop: '0.8rem'}}>Transparencia</h1>
                           </div>
                        </div>  
                        <div className="paragraph">
                         Es un valor aplicado a la conducta humana, no significa otra cosa que permitir que los demás entiendan claramente el 
                         mensaje que les estamos proporcionando y perciban un mensaje que exprese lo que realmente deseamos o sentimos. 
                         Ser transparente es ser claro, evidente, 
                         no expresarse con ambigüedad que permita la interpretación, también se refiere a la integridad en la actuación.
                        </div>
                    </div>
               </div>

            </div>
            </Section>
        </React.Fragment>
    );
}

export default Historia;
