import React from 'react';
import Section from '../section/Section.js';
import './Chipalitos.css';

/*images*/
import ChipastickSemillas from './images/chipasticks_semillas.png';
import ChipastickLogo from './images/chipasticks_logo.png';
import ChipasticksImg from './images/chipasticks.png';

import Fade from 'react-reveal/Fade';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

   const responsive = {
          superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 3000, min: 0 },
            items: 1,
          },
        };
class Chipalitos extends React.Component{
    render(){

        return (
        	<Section id="chipasticks" className="chipalitos">

                <Fade left>
                    <div className="chipalito-product-carousel">
                        <Carousel
                          additionalTransfrom={0}
                          arrows={false}
                          autoPlay={this.props.deviceType !== "mobile" ? true : false}
                          autoPlaySpeed={3000}
                          centerMode={false}
                          className=""
                          containerClass="container-with-dots"
                          dotListClass=""
                          draggable
                          focusOnSelect={false}
                          infinite
                          itemClass=""
                          keyBoardControl
                          minimumTouchDrag={80}
                          renderButtonGroupOutside={false}
                          renderDotsOutside={false}
                          responsive={responsive}
                          showDots={false}
                          sliderClass=""
                          slidesToSlide={1}
                          swipeable
                        >
                            <div className="chipalitos-product img">
                                <img src={ChipastickSemillas} alt="imagen de chipa stick semillas"/>
                            </div>
                            <div className="chipalitos-product img">
                                <img src={ChipasticksImg} alt="imagen de chipa stick"/>
                            </div>
                        </Carousel>
                    </div>

                </Fade>

                <div className="product">
                    <div className="container">
                        <Fade top>
                            <div className="chipalitos-product logo">
                                <img src={ChipastickLogo} alt="logo de chipasticks"/>
                            </div>
                        </Fade>
                        
                        <Fade right>
                            <div className="description">
                                <h2>
                                    Una versi&oacute;n renovada de la 
                                    chipa tradicional incorporando 
                                    gastronom&iacute;a de vanguardia.
                                </h2>
                                <h3>
                                    Proyecto ganador del Bono Creativo 2018
                                    'Econom&iacute;a Naranja, Fuerza Creativa' de la Fundaci&oacute;n CIRD.
                                </h3>
                            </div>
                        </Fade>
                    </div>
                </div>
        	</Section>
        );
    }
    
}

export default Chipalitos;
