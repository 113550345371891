import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SliderButtons = ({ next, previous, goToSlide, ...rest }) => {
  /*const { carouselState: { currentSlide } } = rest;*/
  return (
    <div className="carousel-button-group">
      <button className="arrow left" onClick={() => previous()}><FontAwesomeIcon icon={faChevronLeft}/></button>
      <button className="arrow right" onClick={() => next()} ><FontAwesomeIcon icon={faChevronRight}/></button>
    </div>
  );
};

export default SliderButtons;