import React from 'react';
import Section from '../section/Section.js';
import './ChipatiaExpress.css';
import ContactElement from '../contact_element/ContactElement';

import ChipaTiaLocalImg from './images/chipaexpress.png'


class ChipatiaExpress extends React.Component {
    render(){
        return (
        	<Section id="express" className="chipatia_express">
                
               <div className="title-container">
                    <h1 className="title one">Chipa tia</h1>
                    <h1 className="title two">Express</h1>
               </div>
            
                <div className='local_img'>
                    <div className='picture'>
                        <img src={ChipaTiaLocalImg} alt='Imagen del local de chipa tia express'/>
                    </div>
                     <div className='contact'>
                        <ContactElement title="Dirección:" 
                              value="Av. Irrazábal entre Curupayty y Padre Kreusser"/>
                        <br/>
                        <ContactElement title="Teléfono:" 
                              value="+595982565121"/>
                    </div>
                </div>
        	</Section>
        );       
    }
}

export default ChipatiaExpress;
