import React from 'react';
import logo from './images/logo_light.png';
import './NavBar.css';

import scrollToElement from 'scroll-to-element';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

class NavBar extends React.Component{

	/*Change the menu item to active when on click*/
	setActiveAndScroll = (event, url) =>{
		event.preventDefault();
		const menu_elements = document.querySelectorAll(".nav span");
		menu_elements.forEach((element)=> element.classList.remove("active"));
		event.target.parentElement.classList.add("active")
		scrollToElement(url);
	}

	/*set when loaded for the first time*/
	setFirsTime = () =>{
		const menu_elements = document.querySelectorAll(".nav span");
		menu_elements.forEach((element)=> element.classList.remove("active"));
		scrollToElement('#home');
		menu_elements[0].classList.add("active");
		
	}

	/*change the menu on scrolling*/
	changeMenuOnScrolling = (currentHeight, nav_anchor, nav) => {
		const currentScrollPosition = window.pageYOffset;
		const ratio = currentScrollPosition/currentHeight;
		if(currentScrollPosition === 0){
			nav_anchor.classList.remove("scrolled");
			nav.classList.remove("scrolled");
			return;
		}
		if(ratio>0.2 && !nav_anchor.classList.contains("scrolled")){
			nav_anchor.classList.add("scrolled");
			nav.classList.add("scrolled");
		}
		else if(ratio<=0.2 && nav_anchor.classList.contains("scrolled")){
			nav_anchor.classList.remove("scrolled");
			nav.classList.remove("scrolled");
		}
	}

	/*add function for scrolling so menu active changes accordingly*/
	setActiveOnScroll = (lastId, topMenuHeight, scrollItems, menu_elements) =>{
		// Cache selectors
		let fromTop = window.scrollY+topMenuHeight;
		let current = scrollItems.filter((scrollItem)=>{
			if (scrollItem.offsetTop < fromTop){
				return scrollItem;
			}
		});
		current = current[current.length-1];
		const id = current ? current.getAttribute('id') : "";
		if (lastId !== id){
			lastId = id;
			menu_elements.forEach((element)=> element.classList.remove("active"));
			menu_elements.forEach((element)=>{
				if(element.children[0].getAttribute('href').replace('#','')===lastId){
					element.classList.add("active");
				}
			})
		}
	}

	/*show menu*/
	showMenu =() =>{
		const menu = document.getElementById('nav-menu');
		menu.classList.add('visible');

		const menu_button = document.getElementById('menu-button');
		menu_button.classList.add('hidden');

		const close_menu_button = document.querySelector('.menu-button.close');
		close_menu_button.classList.remove('hidden');
	}

	hideMenu = ()=>{
		const menu = document.getElementById('nav-menu');
		menu.classList.remove('visible');

		const menu_button = document.getElementById('menu-button');
		menu_button.classList.remove('hidden');

		const close_menu_button = document.querySelector('.menu-button.close');
		close_menu_button.classList.add('hidden');
	}

	/*when menu is loaded*/
	componentDidMount() {
		//set the menu up
		this.setFirsTime();

		const currentHeight = window.innerHeight;
		const nav_anchor = document.querySelector(".nav-anchor");
		const nav = document.querySelector(".nav");
		const changeMenuOnScrollingFunction = () =>{this.changeMenuOnScrolling(currentHeight, nav_anchor, nav)};

		//set cache selectors
		const topMenu = document.querySelector(".nav");
		let lastId = document.querySelector(".nav");
		const topMenuHeight = topMenu.offsetHeight+1;
		 // All list items
		const menuItems = topMenu.getElementsByTagName("a");
		const scrollItems = [];
		for (let i=0; i< menuItems.length; i++){
			const section_id = menuItems.item(i).getAttribute('href').replace('#','').toString();
			scrollItems.push(document.getElementById(section_id.toString()));
		}

		const menu_elements = document.querySelectorAll(".nav span");

		const setActiveOnScrollFunction = () =>{this.setActiveOnScroll(lastId, topMenuHeight, scrollItems, menu_elements)}
		const showHideMenu = () => {this.hideMenu()};
		/*for change on scrolling function*/
		window.addEventListener("scroll", () =>{
			changeMenuOnScrollingFunction();
			setActiveOnScrollFunction();
			showHideMenu();
		})
  	}

	render(){
		return (
	    <div className="nav-anchor">
	    	<div style={{position:'relative'}}>
		    	<button id="menu-button" onClick={this.showMenu} className="menu-button">
		    		<FontAwesomeIcon icon={faBars}/>
		    	</button>
		    	<button id="close_menu_button" onClick={this.hideMenu} className="menu-button close hidden">
		    		<FontAwesomeIcon icon={faTimesCircle}/>
		    	</button>
	    	</div>
	        <nav id="nav-menu" className="nav">
	        	<div className="logo">
	        		<img src={logo} alt="Chipa Tia S.R.L."/>
	        	</div>
	        	<span className="active">
	        	  <a href="#home" onClick={(event)=>this.setActiveAndScroll(event, '#home')}>Inicio</a>
	        	</span>
	        	<span>
	        	  <a href="#historia"  onClick={(event)=>this.setActiveAndScroll(event, '#historia')}>Nuestra historia</a>
	        	</span>
	        	<span>
				  <a href="#productos"  onClick={(event)=>this.setActiveAndScroll(event, '#productos')}>Productos</a>
				</span>
	        	<span>
				  <a href="#chipasticks" onClick={(event)=>this.setActiveAndScroll(event, '#chipasticks')}>Chipasticks</a>
				</span>
				<span>
				  <a href="#express" onClick={(event)=>this.setActiveAndScroll(event, '#express')}>Chipa Tia - Express</a>
				</span>
	        	<span>
				  <a href="#servicios" onClick={(event)=>this.setActiveAndScroll(event, '#servicios')}>Servicios</a>
				</span>
	        	<span>
				  <a href="#contactos" onClick={(event)=>this.setActiveAndScroll(event, '#contactos')}>Contactos</a>
				</span>
	        </nav>
	    </div>
    );
	}
    
}

export default NavBar;